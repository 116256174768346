// @ts-ignore

import { Carrier } from 'app/pages/ToursPage/types';
import { compareAsc, differenceInBusinessDays, parseISO } from 'date-fns';
import { Tour } from 'app/models/Tour';
import { Destination } from 'app/models/Destination';
import { ProductionPiece } from 'app/models/ProductionPiece';
import { Workstation } from 'app/containers/OrderDetails/types';

export function findTour(
  date: Date,
  carrier: Carrier,
  destination: Destination,
  tours: Tour[],
): Tour | undefined {
  for (const tour of tours) {
    if (
      tour.carrier?.id === carrier.id &&
      tour.destination?.aggregatedDestinationName ===
        destination.aggregatedDestinationName
    ) {
      const tourDate = parseISO(tour.date);
      if (date.setHours(0, 0, 0, 0) === tourDate.setHours(0, 0, 0, 0)) {
        return tour;
      }
    }
  }

  return undefined;
}

export function findDirectTour(tours: Tour[]): Tour | undefined {
  for (const tour of tours) {
    if (tour.destination?.name === 'Direct') {
      return tour;
    }
  }
  return undefined;
}

export function findTourShop(
  destination: Destination,
  tours: Tour[],
): Tour | undefined {
  let closedTour: Tour | undefined = undefined;
  for (const tour of tours) {
    if (
      tour.destination?.id === destination.id &&
      differenceInBusinessDays(parseISO(tour.date), new Date()) > 3
    ) {
      if (
        closedTour === undefined ||
        compareAsc(parseISO(closedTour.date), parseISO(tour.date)) > 0
      ) {
        closedTour = tour;
      }
    }
  }

  return closedTour;
}

export const pieceRef = (ref: string) => {
  if (ref.startsWith('*custom')) {
    return `Personnalisé`;
  }
  if (ref[0] === '*') {
    return `Personnalisé (${ref.slice(1, ref.indexOf('-'))})`;
  }

  return ref.replace(/ *\[[^\]]*]/, '');
};

export const pieceRefWithProductionPiece = (
  piece: ProductionPiece,
  cutting = false,
) => {
  if (piece.product && piece.product.uniqueProduct) return 'SPECIAL';
  if (piece.station === Workstation.WoodworkSpecial && cutting) {
    return piece.description ? piece.description[0] : '';
  }

  return pieceRef(piece.reference);
};
